import React from 'react';
import { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LandingBody from './landing-body';
import Services from './services';

export default class Home extends React.Component {
  public render(): ReactNode {
    return (
      <div>
        <Container>
          <Row className="justify-content-md-center">
            <Col xs lg="2" />
            <Col md="auto">
              <LandingBody />
            </Col>
            <Col xs lg="2" />
          </Row>
        </Container>
        <Container>
          <Services />
        </Container>
      </div>
    );
  }
}
