import React, { MouseEvent } from 'react';
import { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import './custom-nav.css';
import logo from '../naturally-well-logo.png';

export default class CustomNav extends React.Component {
  private handleNatuallyWellTVClick(
    event: MouseEvent<HTMLButtonElement>
  ): void {
    event.preventDefault();
    // window.open('/#', '_blank');
  }

  public render(): ReactNode {
    return (
      <Navbar className="nav">
        <LinkContainer to="/">
          <Navbar.Brand className="nav-brand">
            <Image src={logo} alt="Naturally Well" fluid />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" />
        <Nav className="nav-links justify-content-end">
          <NavDropdown title="Events" id="nav-events">
            <LinkContainer to="/calendar">
              <NavDropdown.Item>Calendar</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/events">
              <NavDropdown.Item>Classes, Groups, Workshops</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>
        <Button className="tv-button" onClick={this.handleNatuallyWellTVClick}>
          Naturally Well TV
        </Button>
      </Navbar>
    );
  }
}
