import React from 'react';
import { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ServiceReveal from './service-reveal';
import './services.css';

export default class Services extends React.Component {
  public render(): ReactNode {
    return (
      <div>
        <Container className="services-container">
          <Row>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Chiropractic"
                link="https://naturallyaligned.com"
                text="Chiropractic"
                external={true}
              />
            </Col>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Mental Health"
                link="https://wildtreewellness.com/home"
                text="Mental Health"
                external={true}
              />
            </Col>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Functional Nutrition"
                link="https://www.wholeyounutritionmn.com"
                text="Functional Nutrition"
                external={true}
              />
            </Col>
          </Row>
          <Row>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Yoga"
                link="https://reviveyoumn.wixsite.com/reviveyou"
                text="Yoga"
                external={true}
              />
            </Col>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Midwifery Care"
                link="https://twincitiesmidwifery.com"
                text="Midwifery Care"
                external={true}
              />
            </Col>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Health Coach"
                link="/healthcoach"
                text="Health Coach"
                external={false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Occupational Therapy"
                link="https://happy-and-healthy.net/"
                text="Occupational Therapy"
                external={true}
              />
            </Col>
            <Col className="services-column" sm>
              <ServiceReveal
                altText="Craniosacral Therapy"
                link="https://happy-and-healthy.net/"
                text="Craniosacral Therapy"
                external={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
