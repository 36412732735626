import React from 'react';
import { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './landing-body.css';

export default class LandingBody extends React.Component {
  public render(): ReactNode {
    return (
      <Container>
        <Row>
          <Col sm={12}>
            <div>
              <h2 className="brand-header">support // education // wellness</h2>
              <h3 className="brand-header">
                We are a collective of holistic wellness care providers.
              </h3>
              <p className="brand-body">
                Do you wish you knew how to <i>take better care of yourself?</i>
                {'  '}
                Trust us, we&apos;ve all been there. We know how it feels to
                rush through each day on autopilot, never taking any time to{' '}
                <b>breathe</b>, <b>relax</b>, or {'  '}
                <b>take care of your own needs</b>.
              </p>
              <p className="brand-body">
                In theory self-care is simple and seemingly obvious, but in
                reality it&apos;s something often overlooked.{'  '}
                <b>
                  Self-care is any deliberate action to improve of your mental,
                  emotional, and physical health
                </b>
                . Good self-care is key to improved mood, optimal body function
                and reduced anxiety. It’s also key to a good relationship with
                yourself and others.
              </p>
              <p className="brand-body">
                <b>
                  Think of us as White Bear Lake&apos;s &quot;self-care
                  central&quot;
                </b>
                . We strive to provide a space where people can gather, heal and
                connect with a community that promotes self-care. A place where
                you can visit often and stay awhile; a place where you are
                nurtured, valued and empowered. A place where you feel inspired
                to make choices that allow you the life you deserve.
              </p>
              <p className="brand-body">
                &#123; Oh, and in case you were wondering, self-care isn’t
                selfish. It&apos;s about knowing what you need to do to take
                care of yourself, so subsequently, you can take care of others
                as well. &#125;
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
