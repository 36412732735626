/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomNav from './components/custom-nav';
import CustomerFooter from './components/custom-footer';
import './App.css';
import Home from './components/home';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import WebFont from 'webfontloader';

const Calendar = lazy(() => import('./components/calendar'));
const Events = lazy(() => import('./components/events'));
const JoinOurTeam = lazy(() => import('./components/join-our-team'));
const Acupuncture = lazy(() => import('./components/acupuncture'));
const HealthCoach = lazy(() => import('./components/healthcoach'));

const App: React.FC = () => {
  library.add(faFacebook, faInstagram);
  WebFont.load({
    google: {
      families: ['Nunito Sans:400', 'sans-serif']
    }
  });
  return (
    <div>
      <CustomNav />
      <div className="main-body">
        <Suspense
          fallback={
            <Container>
              <Row>
                <p />
              </Row>
              <Row>
                <div className="loader center">
                  <i className="fa fa-pulse fa-spinner fa-8x" />
                </div>
              </Row>
              <Row>
                <p />
              </Row>
            </Container>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/join-our-team" component={JoinOurTeam} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/acupuncture" component={Acupuncture} />
            <Route exact path="/healthcoach" component={HealthCoach} />
          </Switch>
        </Suspense>
      </div>
      <CustomerFooter />
    </div>
  );
};

export default App;
