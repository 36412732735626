import React from 'react';
import { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './custom-footer.css';
import { Link } from 'react-router-dom';

export default class CustomFooter extends React.Component {
  public render(): ReactNode {
    return (
      <Navbar className="footer">
        <Container>
          <Row className="footer-row">
            <Col className="footer-col" sm>
              <div className="footer-header-text">Naturally Well WBL</div>
              Home to multiple holistic wellness care providers serving families
              in the Northeast metro, focusing on wellness during pregnancy,
              postpartum and parenting.
            </Col>
            <Col className="footer-col" sm>
              <div className="footer-header-text">Connect With Us</div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://naturallywellwbl.us10.list-manage.com/subscribe?u=9467b0df256e9e598e2022c4a&id=88fed102ab"
                  className="footer-link"
                >
                  Join our Mailing List
                </a>
              </div>
              <div>
                <Link to="/join-our-team" className="footer-link">
                  <div>Join our Team</div>
                </Link>
              </div>
              <a
                className="footer-link"
                href="mailto:naturallywellwbl@gmail.com"
              >
                naturallywellwbl@gmail.com
              </a>
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/NaturallyWellWBL/"
              >
                <FontAwesomeIcon
                  className="social-icon"
                  icon={['fab', 'facebook']}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/wblnaturallywell/"
              >
                <FontAwesomeIcon
                  className="social-icon"
                  icon={['fab', 'instagram']}
                />
              </a>
            </Col>
            <Col className="footer-col" sm>
              <div className="footer-header-text">Location</div>
              <a
                className="footer-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/uu36SYh6dm1Pmg538"
              >
                2025 4th St. Suite 100
                <br />
                White Bear Lake, MN
              </a>
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
}
