import React from 'react';
import { ReactNode } from 'react';
import posed from 'react-pose';
import './service-reveal.css';
import { Link } from 'react-router-dom';

export default class ServiceReveal extends React.Component<
  { text: string; link: string; altText: string; external: boolean },
  { hovered: boolean }
> {
  public constructor(
    props: { text: string; link: string; altText: string; external: boolean },
    context: {}
  ) {
    super(props, context);
    this.state = {
      hovered: false
    };
  }

  private Circle = posed.div({
    hoverable: true,
    pressable: true,
    init: {
      scale: 1,
      boxShadow: '0px 0px 0px rgba(0,0,0,0)',
      filter: 'grayscale(20%) blur(0px)',
      transition: { duration: 150 }
    },
    hover: {
      scale: 1.2,
      boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
      filter: 'grayscale(0%) blur(0px)',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    },
    press: {
      scale: 1.1,
      boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
      filter: 'grayscale(10%) blur(0.5px)',
      transition: { duration: 150 }
    }
  });

  private createTarget(): string {
    if (this.props.link.startsWith('#')) {
      return '_self';
    } else {
      return '_blank';
    }
  }

  private onMouseEnter = (): void => {
    this.setState({ hovered: true });
  };

  private onMouseLeave = (): void => {
    this.setState({ hovered: false });
  };

  public render(): ReactNode {
    let circle = (
      <this.Circle
        className="circle"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="service-link">{this.props.text}</div>
      </this.Circle>
    );

    if (this.props.external) {
      return (
        <div>
          <a
            className="service-link"
            rel="noopener noreferrer"
            target={this.createTarget()}
            title={this.props.altText}
            href={this.props.link}
          >
            {circle}
          </a>
        </div>
      );
    }

    return (
      <div>
        <Link className="service-link" to={this.props.link}>
          {circle}
        </Link>
      </div>
    );
  }
}
